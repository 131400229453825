import React, { useState } from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import Seo from "../components/seo"
import Modal from "../components/videoModal"
import imgOne from "../svg/ikr3.svg"
import imgTwo from "../svg/ikr2.svg"
import { BsSpeedometer2 } from "react-icons/bs"
import { FaRegFileVideo, FaPeopleArrows, FaUsers } from "react-icons/fa"
import { AiOutlineFileSearch } from "react-icons/ai"
import { StaticImage } from "gatsby-plugin-image"

const communicationFeatures = [
  {
    id: 1,
    name: "Preserve video meetings",
    description:
      "Upload Zoom, Webex, Teams, GotoMeeting, and other video calls for automatically generated video-to-text transcripts.",
    icon: FaRegFileVideo,
  },
  {
    id: 2,
    name: "Searchable video archive",
    description:
      "Simplify the development of a searchable library of institutional knowledge, and minimize the risks of losing valuable information forever.",
    icon: AiOutlineFileSearch,
  },
]

const transferFeatures = [
  {
    id: 1,
    name: "Time savings",
    description:
      "Eliminate unproductive hours searching for essential information across your entire staff.",
    icon: BsSpeedometer2,
  },
  {
    id: 2,
    name: "Onboarding new hires",
    description:
      "Streamlines the training of new employees by giving them access to your recorded interviews with subject matter experts or HR personnel. ",
    icon: FaUsers,
  },
  {
    id: 3,
    name: "Training platform",
    description:
      "Maximize your one-on-one training time by providing these background video training suites in advance.",
    icon: FaPeopleArrows,
  },
]

const IndexPage = () => {
  const [open, setOpen] = useState(false)
  const [url, setUrl] = useState("")
  return (
    <Layout>
      <Seo title="Home" />
      <Hero setOpen={setOpen} setUrl={setUrl} />
      <Features setOpen={setOpen} setUrl={setUrl} />

      <Modal open={open} setOpen={setOpen} url={url} />
    </Layout>
  )
}

const Hero = ({ setOpen, setUrl }) => {
  return (
    <section className="relative bg-white overflow-hidden">
      <div
        className="hidden lg:block lg:absolute lg:inset-0"
        aria-hidden="true"
      >
        <svg
          className="absolute top-0 left-1/2 transform translate-x-64 -translate-y-8"
          width={640}
          height={784}
          fill="none"
          viewBox="0 0 640 784"
        >
          <defs>
            <pattern
              id="9ebea6f4-a1f5-4d96-8c4e-4c2abf658047"
              x={118}
              y={0}
              width={20}
              height={20}
              patternUnits="userSpaceOnUse"
            >
              <rect
                x={0}
                y={0}
                width={4}
                height={4}
                className="text-gray-200"
                fill="currentColor"
              />
            </pattern>
          </defs>
          <rect
            y={72}
            width={640}
            height={640}
            className="text-gray-50"
            fill="currentColor"
          />
          <rect
            x={118}
            width={404}
            height={784}
            fill="url(#9ebea6f4-a1f5-4d96-8c4e-4c2abf658047)"
          />
        </svg>
      </div>

      <div className="relative pt-6 lg:pt-24 pb-16 sm:pb-24 lg:pb-44">
        <div className="mt-16 mx-auto max-w-7xl px-4 sm:mt-24 sm:px-6 lg:mt-32">
          <div className="lg:grid lg:grid-cols-12 lg:gap-8">
            <div className="sm:text-center md:max-w-2xl md:mx-auto lg:col-span-6 lg:text-left">
              <h1>
                <span className="block text-sm font-semibold uppercase tracking-wide text-gray-500 sm:text-base lg:text-sm xl:text-base">
                  Knowledge Retention
                </span>
                <span className="mt-1 block text-4xl tracking-tight font-extrabold sm:text-5xl xl:text-6xl">
                  <span className="block text-gray-900">The future of</span>
                  <span className="block text-indigo-600">
                    your legacy knowledge
                  </span>
                </span>
              </h1>
              <p className="mt-3 text-base text-gray-500 sm:mt-5 sm:text-xl lg:text-lg xl:text-xl">
                A powerful, user-friendly interface for collaboration, building,
                and updating your institutional knowledge data.
              </p>
              <div className="mt-10 sm:flex sm:justify-center lg:justify-start">
                <div className="rounded-md shadow">
                  <Link
                    to="/solutions"
                    className="w-full flex items-center justify-center px-8 py-2 border border-transparent text-base font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 md:py-3 md:text-lg md:px-10"
                  >
                    Learn More
                  </Link>
                </div>
              </div>
            </div>

            <div className="mt-12 relative sm:max-w-lg sm:mx-auto lg:mt-0 lg:max-w-none lg:mx-0 lg:col-span-6 lg:flex lg:items-center">
              <svg
                className="absolute top-0 left-1/2 transform -translate-x-1/2 -translate-y-8 scale-75 origin-top sm:scale-100 lg:hidden"
                width={640}
                height={784}
                fill="none"
                viewBox="0 0 640 784"
                aria-hidden="true"
              >
                <defs>
                  <pattern
                    id="4f4f415c-a0e9-44c2-9601-6ded5a34a13e"
                    x={118}
                    y={0}
                    width={20}
                    height={20}
                    patternUnits="userSpaceOnUse"
                  >
                    <rect
                      x={0}
                      y={0}
                      width={4}
                      height={4}
                      className="text-gray-200"
                      fill="currentColor"
                    />
                  </pattern>
                </defs>
                <rect
                  y={72}
                  width={640}
                  height={640}
                  className="text-gray-50"
                  fill="currentColor"
                />
                <rect
                  x={118}
                  width={404}
                  height={784}
                  fill="url(#4f4f415c-a0e9-44c2-9601-6ded5a34a13e)"
                />
              </svg>
              <div className="relative mx-auto w-full rounded-lg shadow-lg lg:max-w-lg">
                <button
                  onClick={e => {
                    setUrl("https://www.youtube.com/embed/Kfp8-8QNH28?rel=0")
                    setOpen(true)
                  }}
                  type="button"
                  className="relative group block w-full bg-indigo-600 rounded-lg overflow-hidden focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-600"
                >
                  <span className="sr-only">Watch our video to learn more</span>
                  <StaticImage
                    src="../images/video.jpg"
                    placeholder="blurred"
                    alt="Video Cover"
                    className="w-full transition group-hover:opacity-75"
                  />

                  <div
                    className="absolute inset-0 w-full h-full flex items-center justify-center group"
                    aria-hidden="true"
                  >
                    <svg
                      className="h-20 w-20 text-indigo-600 transform transition group-hover:scale-105"
                      fill="currentColor"
                      viewBox="0 0 84 84"
                    >
                      <circle
                        opacity="0.9"
                        cx={42}
                        cy={42}
                        r={42}
                        fill="white"
                      />
                      <path d="M55.5039 40.3359L37.1094 28.0729C35.7803 27.1869 34 28.1396 34 29.737V54.263C34 55.8604 35.7803 56.8131 37.1094 55.9271L55.5038 43.6641C56.6913 42.8725 56.6913 41.1275 55.5039 40.3359Z" />
                    </svg>
                  </div>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

const Features = ({ setUrl, setOpen }) => {
  return (
    <div className="py-16 bg-white overflow-hidden lg:pt-24 lg:pb-40">
      <div className="relative max-w-xl mx-auto px-4 sm:px-6 lg:px-8 lg:max-w-7xl">
        <svg
          className="hidden lg:block absolute right-full transform translate-x-1/2 -translate-y-1/4"
          width={404}
          height={784}
          fill="none"
          viewBox="0 0 404 784"
          aria-hidden="true"
        >
          <defs>
            <pattern
              id="b1e6e422-73f8-40a6-b5d9-c8586e37e0e7"
              x={0}
              y={0}
              width={20}
              height={20}
              patternUnits="userSpaceOnUse"
            >
              <rect
                x={0}
                y={0}
                width={4}
                height={4}
                className="text-gray-200"
                fill="currentColor"
              />
            </pattern>
          </defs>
          <rect
            width={404}
            height={784}
            fill="url(#b1e6e422-73f8-40a6-b5d9-c8586e37e0e7)"
          />
        </svg>

        <div className="relative">
          <h2 className="text-center text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
            A better way preserve knowledge
          </h2>
          <p className="mt-4 max-w-3xl mx-auto text-center text-xl text-gray-500">
            Qwarkle is an expedient platform for staff to archive, research, and
            share project information across your organization.
          </p>
        </div>

        <div className="relative mt-12 sm:mt-16 lg:mt-24">
          <div className="lg:grid lg:grid-flow-row-dense lg:grid-cols-2 lg:gap-8 lg:items-center">
            <div className="lg:col-start-2">
              <h3 className="text-2xl font-extrabold text-gray-900 tracking-tight sm:text-3xl">
                Leverage artificial intelligence
              </h3>
              <p className="mt-3 text-lg text-gray-500">
                Qwarkle is powered by multiple deep learning neural network
                algorithms to give you valuable insights into your video
                content.
              </p>
              <div className="mt-3 relative mx-auto w-full rounded-lg shadow-lg lg:max-w-lg">
                <button
                  onClick={e => {
                    setUrl("https://www.youtube.com/embed/KMW3j7UIr8A?rel=0")
                    setOpen(true)
                  }}
                  type="button"
                  className="relative group block w-full bg-indigo-600 rounded-lg overflow-hidden focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-600"
                >
                  <span className="sr-only">Watch our video to learn more</span>
                  <StaticImage
                    src="../images/video2.jpg"
                    placeholder="blurred"
                    alt="Video Cover"
                    className="w-full transition group-hover:opacity-75"
                  />

                  <div
                    className="absolute inset-0 w-full h-full flex items-center justify-center group"
                    aria-hidden="true"
                  >
                    <svg
                      className="h-20 w-20 text-indigo-600 transform transition group-hover:scale-105"
                      fill="currentColor"
                      viewBox="0 0 84 84"
                    >
                      <circle
                        opacity="0.9"
                        cx={42}
                        cy={42}
                        r={42}
                        fill="white"
                      />
                      <path d="M55.5039 40.3359L37.1094 28.0729C35.7803 27.1869 34 28.1396 34 29.737V54.263C34 55.8604 35.7803 56.8131 37.1094 55.9271L55.5038 43.6641C56.6913 42.8725 56.6913 41.1275 55.5039 40.3359Z" />
                    </svg>
                  </div>
                </button>
              </div>

              {/* <dl className="mt-10 space-y-10">
                {communicationFeatures.map(item => (
                  <div key={item.id} className="relative">
                    <dt>
                      <div className="absolute flex items-center justify-center h-12 w-12 rounded-md bg-indigo-600 text-white">
                        <item.icon className="h-6 w-6" aria-hidden="true" />
                      </div>
                      <p className="ml-16 text-lg leading-6 font-medium text-gray-900">
                        {item.name}
                      </p>
                    </dt>
                    <dd className="mt-2 ml-16 text-base text-gray-500">
                      {item.description}
                    </dd>
                  </div>
                ))}
              </dl> */}
            </div>

            <div className="mt-10 -mx-4 relative lg:mt-0 lg:col-start-1">
              <svg
                className="absolute left-1/2 transform -translate-x-1/2 translate-y-16 lg:hidden"
                width={784}
                height={404}
                fill="none"
                viewBox="0 0 784 404"
                aria-hidden="true"
              >
                <defs>
                  <pattern
                    id="e80155a9-dfde-425a-b5ea-1f6fadd20131"
                    x={0}
                    y={0}
                    width={20}
                    height={20}
                    patternUnits="userSpaceOnUse"
                  >
                    <rect
                      x={0}
                      y={0}
                      width={4}
                      height={4}
                      className="text-gray-200"
                      fill="currentColor"
                    />
                  </pattern>
                </defs>
                <rect
                  width={784}
                  height={404}
                  fill="url(#e80155a9-dfde-425a-b5ea-1f6fadd20131)"
                />
              </svg>
              <img className="relative mx-auto" src={imgOne} alt="" />
            </div>
          </div>
        </div>

        <svg
          className="hidden lg:block absolute left-full transform -translate-x-1/2 translate-y-12"
          width={404}
          height={784}
          fill="none"
          viewBox="0 0 404 784"
          aria-hidden="true"
        >
          <defs>
            <pattern
              id="64e643ad-2176-4f86-b3d7-f2c5da3b6a6d"
              x={0}
              y={0}
              width={20}
              height={20}
              patternUnits="userSpaceOnUse"
            >
              <rect
                x={0}
                y={0}
                width={4}
                height={4}
                className="text-gray-200"
                fill="currentColor"
              />
            </pattern>
          </defs>
          <rect
            width={404}
            height={784}
            fill="url(#64e643ad-2176-4f86-b3d7-f2c5da3b6a6d)"
          />
        </svg>
        <div className="relative mt-12 lg:mt-24 lg:grid lg:grid-cols-2 lg:gap-8 lg:items-center">
          <div className="relative">
            <h3 className="text-2xl font-extrabold text-gray-900 tracking-tight sm:text-3xl">
              Facilitate collaboration
            </h3>
            <p className="mt-3 text-lg text-gray-500">
              This can be done in the field or office environment, from
              virtually anywhere in the world. Upload video material from
              smartphones in the field, or connect from various office locations
              to examine the data and reach consensus.
            </p>

            <dl className="mt-10 space-y-10">
              {transferFeatures.map(item => (
                <div key={item.id} className="relative">
                  <dt>
                    <div className="absolute flex items-center justify-center h-12 w-12 rounded-md bg-indigo-600 text-white">
                      <item.icon className="h-6 w-6" aria-hidden="true" />
                    </div>
                    <p className="ml-16 text-lg leading-6 font-medium text-gray-900">
                      {item.name}
                    </p>
                  </dt>
                  <dd className="mt-2 ml-16 text-base text-gray-500">
                    {item.description}
                  </dd>
                </div>
              ))}
            </dl>
          </div>

          <div className="mt-10 -mx-4 relative lg:mt-0" aria-hidden="true">
            <svg
              className="absolute left-1/2 transform -translate-x-1/2 translate-y-16 lg:hidden"
              width={784}
              height={404}
              fill="none"
              viewBox="0 0 784 404"
            >
              <defs>
                <pattern
                  id="ca9667ae-9f92-4be7-abcb-9e3d727f2941"
                  x={0}
                  y={0}
                  width={20}
                  height={20}
                  patternUnits="userSpaceOnUse"
                >
                  <rect
                    x={0}
                    y={0}
                    width={4}
                    height={4}
                    className="text-gray-200"
                    fill="currentColor"
                  />
                </pattern>
              </defs>
              <rect
                width={784}
                height={404}
                fill="url(#ca9667ae-9f92-4be7-abcb-9e3d727f2941)"
              />
            </svg>
            <img className="relative mx-auto" src={imgTwo} alt="" />
          </div>
        </div>
      </div>
    </div>
  )
}

export default IndexPage
